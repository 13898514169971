import type { NextPage } from 'next';
import Head from 'next/head';

import styled from 'styled-components';
import Throbber from '../src/components/Throbber';

const Home: NextPage = () => (
  // This page only gets hit when JS has not kicked it yet
  // On very slow 3G it might be visible so we should a loading animation
  <Main>
    <Head>
      <title>Loading</title>
    </Head>
    <Throbber />
  </Main>
);

const Main = styled.main`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;

export default Home;
